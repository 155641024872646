// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-desktop-presets-js": () => import("./../../../src/pages/desktop-presets.js" /* webpackChunkName: "component---src-pages-desktop-presets-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-presets-js": () => import("./../../../src/pages/mobile-presets.js" /* webpackChunkName: "component---src-pages-mobile-presets-js" */),
  "component---src-pages-presets-js": () => import("./../../../src/pages/presets.js" /* webpackChunkName: "component---src-pages-presets-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-preset-index-js": () => import("./../../../src/templates/Preset/index.js" /* webpackChunkName: "component---src-templates-preset-index-js" */)
}

